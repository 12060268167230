var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RoleCode } from '../@types/User';
import { useAuthContext } from '../auth/useAuthContext';
import { useAnalyticsPermission, useBalancePermission, useOfficesPermissions, useCalendarPermissions, useCallPermissions, useCodesPermissions, useExpensesPermissions, useKpisPermissions, useNotesPermissions, useNotifsPermissions, useStatClientAnswersPermissions, useTodosPermissions, useCashRegisterPermissions, useUserPermissions, useFolderPermissions, useStatClientPermissions, useAgentPerformancePermissions, useBonusAmountPermissions, usePaymentNotesPermissions, useDepartementPermission, useLevelPermissions, useBookPermissions, useBookStockPermissions, usebookSaleTransaction, useBookCashDeskPayment, useBooksAnalytics, } from './Permission';
var usePermission = function () {
    var user = useAuthContext().user;
    var isSuperAdmin = (user === null || user === void 0 ? void 0 : user.role) === RoleCode.SUPER_ADMIN;
    var isAuthorized = user === null || user === void 0 ? void 0 : user.isAuthorized;
    var permissions = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ isSuperAdmin: isSuperAdmin, isAuthorized: isAuthorized }, useCalendarPermissions(user)), useCallPermissions(user)), useCodesPermissions(user)), useKpisPermissions(user)), useStatClientAnswersPermissions(user)), useNotesPermissions(user)), useTodosPermissions(user)), useNotifsPermissions(user)), useExpensesPermissions(user)), useAnalyticsPermission(user)), useBalancePermission(user)), useCashRegisterPermissions(user)), useUserPermissions(user)), useFolderPermissions(user)), useOfficesPermissions(user)), useStatClientPermissions(user)), useAgentPerformancePermissions(user)), useBonusAmountPermissions(user)), usePaymentNotesPermissions(user)), useLevelPermissions(user)), useBookPermissions(user)), useBookStockPermissions(user)), usebookSaleTransaction(user)), useDepartementPermission(user)), useBookCashDeskPayment(user)), useBooksAnalytics(user));
    return permissions;
};
export default usePermission;
